table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
    color: #141a39;
    cursor: default;
  }
.table td{
    border-top: 0px !important  ;
    border-bottom: 0px !important;
    vertical-align: center !important;
}
  
  tr {
    transition: all 0.2s ease-in-out;
    border-radius: 0.2rem;
  }
  
  tr:hover {
    background-color: #fff;
    transform: scale(1.1);
    -webkit-box-shadow: 0px 5px 15px 8px #e4e7fb;
    box-shadow: 0px 5px 15px 8px #e4e7fb;
  }
  
  tr:nth-child(odd) {
    background-color: #f9f9f9;
  }
  
  /* tr:nth-child(1) {
    color: #fff;
  } */
  
  td {
    height: 5rem;
    font-family: "Rubik", sans-serif;
    font-size: 1.4rem;
    padding: 1rem 2rem;
    position: relative;
  }
  
  .number {
    width: 1rem;
    font-size: 2.2rem;
    font-weight: bold;
    text-align: left;
  }
  
  .name {
    text-align: left;
    font-size: 1.2rem;
  }
  
  .points {
    font-weight: bold;
    font-size: 1rem;
    display: flex; 
    justify-content: flex-end;
    /* align-items: center; */
  }
  
  .points:first-child {
    width: 10rem;
  }
  
  .gold-medal {
    height: 3rem;
    margin-left: 1.5rem;
  }
  
  .ribbon {
    width: 100%;
    height: 5.5rem;
    top: -0.5rem;
    background-color: #5c5be5;
    position: absolute;
    left: -1rem;
    -webkit-box-shadow: 0px 15px 11px -6px #7a7a7d;
    box-shadow: 0px 15px 11px -6px #7a7a7d;
  }
  
  .ribbon::before {
    content: "";
    height: 1.5rem;
    width: 1.5rem;
    bottom: -0.8rem;
    left: 0.35rem;
    transform: rotate(45deg);
    background-color: #5c5be5;
    position: absolute;
    z-index: -1;
  }
  
  .ribbon::after {
    content: "";
    height: 1.5rem;
    width: 1.5rem;
    bottom: -0.8rem;
    right: 0.35rem;
    transform: rotate(45deg);
    background-color: #5c5be5;
    position: absolute;
    z-index: -1;
  }
  